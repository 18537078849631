import React, {useState} from "react";
import {TimeOffUserAdministration} from "../_core/interfaces/time-off-user-administration.interface";
import {Table, TableColumnProps} from "antd";
import {TableEmployee} from "../../Utils/TableEmployee";
import {useHistory} from "react-router-dom";
import {OverrideUserAvailableDaysModal} from "./OverrideUserAvailableDaysModal";
import moment from "moment";
import {UserInfoShort} from "../../../_core/types";
import {RequestTimeOffModal} from "../_components/RequestTimeOffModal";
import {PublicHoliday} from "../_core/interfaces/public-holiday.interface";

interface TimeOffAdministrationTableProps {
    data: TimeOffUserAdministration[];
    publicHolidaysSeed: PublicHoliday[];
    refreshData: () => void;
}

export const TimeOffAdministrationTable: React.FC<TimeOffAdministrationTableProps> = ({data, refreshData, publicHolidaysSeed}) => {
    const history = useHistory();

    const [selectedUser, setSelectedUser] = useState<TimeOffUserAdministration | null>(null);
    const [selectedUserForAddTimeOff, setSelectedUserForAddTimeOff] = useState<UserInfoShort | null>(null);

    const tableColumns: TableColumnProps<TimeOffUserAdministration>[] = [
        {
            title: "Employee",
            width: "20%",
            sorter: (a: TimeOffUserAdministration, b: TimeOffUserAdministration) => a.user.userName < b.user.userName ? 1: -1,
            render: (record: TimeOffUserAdministration) => {
                return (
                    <TableEmployee employee={record.user} history={history} reverse={true} />
                )
            }
        },
        {
            title: "Statistics",
            width: '20%',
            filters: [
                {
                    text: 'Has pending requests',
                    value: 'pendingRequests',
                },
                {
                    text: 'No available days ',
                    value: 'noAvailableDays',
                },
            ],
            onFilter: (value: any, record: TimeOffUserAdministration) => {
                if(value === 'pendingRequests') {
                    return record.availabilityStats?.daysOffPendingApprovalCount > 0;
                }
                if(value === 'noAvailableDays') {
                    return record.availabilityStats?.daysOffAvailableCount <= 0 || record.availabilityStats?.daysOffAvailableUpcomingCount <= 0;
                }
                return false;
            },
            render: (record: TimeOffUserAdministration) => {
                return (
                    <>
                        <p style={{ marginBottom: 0, fontWeight: 600 }}><label style={{ color: record.availabilityStats?.daysOffAvailableUpcomingCount <= 0 ? 'black' : 'green', fontWeight: 500 }}>Available Days: </label>{record.availabilityStats?.daysOffAvailableCount}</p>
                        <p style={{ marginBottom: 0, fontWeight: 600 }}><label style={{ color: record.availabilityStats?.daysOffPendingApprovalCount <= 0 ? 'black' : '#F1C757', fontWeight: 500 }}>Waiting for Approval: </label>{record.availabilityStats?.daysOffPendingApprovalCount}</p>
                        <p style={{ marginBottom: 0, fontWeight: 600 }}><label style={{ color: (record?.availabilityStats?.daysOffAvailableUpcomingCount || 0) <= 0 ? 'red' : 'black', fontWeight: 500 }}>Remaining Days: </label>{record.availabilityStats?.daysOffAvailableUpcomingCount}</p>
                    </>
                )
            }
        },
        {
            title: "Monthly Report",
            width: '20%',
            filters: [
                {
                    text: 'Has previous month data',
                    value: 'prev',
                },
                {
                    text: 'Has current month data',
                    value: 'current',
                },
                {
                    text: 'Has next month data',
                    value: 'next',
                },
            ],
            onFilter: (value: any, record: TimeOffUserAdministration) => {
                if(value === 'prev') {
                    return record.monthlyStats.lastMonthCount > 0;
                }
                if(value === 'current') {
                    return record.monthlyStats.currentMonthCount > 0;
                }
                if(value === 'next') {
                    return record.monthlyStats.nextMonthCount > 0;
                }
                return false;
            },
            render: (record: TimeOffUserAdministration) => {
                return (
                    <>
                        <p style={{marginBottom: 0, fontWeight: 600}}><label style={{color: record.monthlyStats.lastMonthCount <= 0 ? 'black' : 'green', fontWeight: record.monthlyStats.lastMonthCount <= 0 ? 500 : 700}}>{moment().add(-1, 'month').format('MMMM, YYYY')}: </label>{record.monthlyStats.lastMonthCount}</p>
                        <p style={{marginBottom: 0, fontWeight: 600}}><label style={{color: record.monthlyStats.currentMonthCount <= 0 ? 'black' : 'green', fontWeight: record.monthlyStats.currentMonthCount <= 0 ? 500 : 700}}>{moment().format('MMMM, YYYY')}: </label>{record.monthlyStats.currentMonthCount}</p>
                        <p style={{marginBottom: 0, fontWeight: 600}}><label style={{color: record.monthlyStats.nextMonthCount <= 0 ? 'black' : 'green', fontWeight: record.monthlyStats.nextMonthCount <= 0 ? 500 : 700}}>{moment().add(1, 'month').format('MMMM, YYYY')}: </label>{record.monthlyStats.nextMonthCount}</p>
                    </>
                )
            }
        },
        {
            title: "Actions",
            width: '20%',
            render: (record: TimeOffUserAdministration) => {
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <p style={{marginBottom: 0, color: '#1890ff', fontWeight: 500, cursor: 'pointer'}} onClick={() => setSelectedUser(record)}>Edit</p>
                        <p style={{marginBottom: 0, marginLeft: 10, marginRight: 10}}>|</p>
                        <p style={{marginBottom: 0, color: '#1890ff', fontWeight: 500, cursor: 'pointer'}} onClick={() => setSelectedUserForAddTimeOff(record.user)}>Add Time Off</p>
                    </div>
                )
            }
        }
    ]

    return (
        <>
            <Table
                dataSource={data?.map((val: any) => ({
                    ...val,
                    key: val.id,
                }))}
                pagination={false}
                columns={[...tableColumns]}
            />

            <OverrideUserAvailableDaysModal
                isVisible={selectedUser !== null}
                data={selectedUser}
                onClose={() => setSelectedUser(null)}
                onSuccess={() => {setSelectedUser(null); refreshData()}}
            />

            {
                selectedUserForAddTimeOff &&
                <RequestTimeOffModal
                    isVisible={true}
                    publicHolidays={publicHolidaysSeed}
                    onClose={() => setSelectedUserForAddTimeOff(null)}
                    onSuccess={() => {setSelectedUserForAddTimeOff(null); refreshData()}}
                    userInfo={selectedUserForAddTimeOff}
                />
            }
        </>
    )
}
