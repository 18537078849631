import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Image, Radio,
  Select,
  Skeleton,
  Typography
} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import { TimeOffTable } from "../_components/TimeOffTable";
import {Link, useLocation} from "react-router-dom";
import * as queryString from "querystring";
import avatar from "../../../assets/images/avatar_dark.png";
import {UserContext} from "../../../App";
import {getTimeOffStatusesList} from "../_core/helpers/time-off-general.helper";
import {TimeOffHistoryLogs} from "../_components/TimeOffHistoryLogs";
import {KeyValue} from "../../../_core/types";
import {useTimeOffListingDatasource} from "../_core/hooks/time-off-listing-datasource.hook";
import {UrlQueryParamsHelper} from "../_core/helpers/url-query-params.helper";
import {RequestTimeOffModal} from "../_components/RequestTimeOffModal";
import {TimeOffCalendar} from "../_components/TimeOffCalendar";
import {FullCalendarHelper} from "../_core/helpers/full-calendar.helper";
import {TimeOffView} from "../_core/enums/time-off-view.enum";
const { RangePicker } = DatePicker;

export const UserTimeOffProfile: React.FC = () => {
  const didMount = useRef(false);

  const { user } = useContext(UserContext);
  const location = useLocation();

  const timeOffListingDatasource = useTimeOffListingDatasource();
  const {
    timeOffRequests, isLoading, pagination,
    getUserTimeOffProfileData, initializeFilters,
    userTimeOffProfileMetadata, historyLogs,
    selectedStatus, setSelectedStatus,
    selectedDateRange, setSelectedDateRange,
    getUserProfileMetadata,
    publicHolidaysSeed, currentCalendarMonth
  } = timeOffListingDatasource;

  const [isMyData, setIsMyData] = useState(true);
  const [selectedView, setSelectedView] = useState<TimeOffView>(TimeOffView.Calendar);

  const queryParams: any = queryString.parse(location.search.slice(1));
  const [userId, setUserId] = useState('');
  const [displayRequestTimeOffModal, toggleRequestTimeOffModal] = useState(false);

  useEffect(() => {
    getUserProfileMetadata(queryParams['userId'] || '');
  }, [])

  useEffect(() => {
    if(userId && queryParams['userId'] !== userId) {
     initializeData();
      getUserProfileMetadata(queryParams['userId'] || '');
    }
  }, [queryParams])

  useEffect(() => {
    initializeData();
  }, [selectedStatus, selectedDateRange, selectedView])


  useEffect(() => {
    setIsMyData(userTimeOffProfileMetadata?.user.id === user.id);
  }, [userTimeOffProfileMetadata?.user])

  const initializeData = () => {
    if (didMount.current) {
      getUserTimeOffProfileData(1,queryParams['userId'] || '', selectedView);
      setUserId(queryParams['userId'])

      UrlQueryParamsHelper.setFiltersInQueryParams(
          selectedDateRange, selectedStatus,
          pagination.pageNumber, [], [], queryParams['userId'], selectedView
      );

    } else {
      initializeFilters();
      didMount.current = true;
    }
  }

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
          <>
            <Typography.Title style={{ display: "flex", justifyContent: "flex-start" }}>
              {
                isMyData
                    ? 'My Time Off'
                    : userTimeOffProfileMetadata?.user ? <>
                      <Avatar
                          size={40}
                          style={{marginRight: 7}}
                          src={<Image preview={{ mask: <div /> }} src={userTimeOffProfileMetadata?.user.profilePicture ?? avatar} />}
                      />
                      <Link to={`employees/${userTimeOffProfileMetadata.user.id}`} target={"_blank"} style={{color: 'black'}}>
                        {userTimeOffProfileMetadata?.user.lastName || ''} {userTimeOffProfileMetadata?.user.firstName || ''}
                      </Link>'s Time Off
                    </> : ''
              }
            </Typography.Title>

            {
              (isMyData || (userTimeOffProfileMetadata?.user && !userTimeOffProfileMetadata.hasRestrictedTimeOffData)) &&
                <div style={{ marginBottom: 25}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{ textAlign: 'left', paddingRight: 20, borderRight: userTimeOffProfileMetadata?.availabilityStats?.daysOffPendingApprovalCount === 0 ? '' : '1px solid #efefef'}}>
                      <p style={{ marginBottom: 0, fontSize: 30, fontWeight: 700, color: userTimeOffProfileMetadata?.availabilityStats?.daysOffPendingApprovalCount === 0 ? 'green': ''}}>
                        {userTimeOffProfileMetadata?.availabilityStats?.daysOffAvailableCount}
                      </p>
                      <label style={{color: 'gray'}}>Available days</label>
                    </div>

                    {
                      userTimeOffProfileMetadata?.availabilityStats?.daysOffPendingApprovalCount !== 0 &&
                        <>
                          <div style={{textAlign: 'left', padding: '0 20px', borderRight: '1px solid #efefef'}}>
                          <p style={{ marginBottom: 0, fontSize: 30, fontWeight: 700, color: '#F1C757' }}>{userTimeOffProfileMetadata?.availabilityStats?.daysOffPendingApprovalCount}</p>
                            <label style={{color: 'gray'}}>Waiting for approval</label>
                          </div>

                          <div style={{textAlign: 'left', paddingLeft: 20}}>
                          <p style={{ marginBottom: 0, fontSize: 30, fontWeight: 700, color: (userTimeOffProfileMetadata?.availabilityStats?.daysOffAvailableUpcomingCount || 0) <= 0 ? 'red' : 'green' }}>{userTimeOffProfileMetadata?.availabilityStats?.daysOffAvailableUpcomingCount}</p>
                            <label style={{color: 'gray'}}>Remaining days</label>
                          </div>
                        </>
                    }
                  </div>
                </div>
            }

            {
                (isMyData || userTimeOffProfileMetadata?.user) &&
                <div style={{ marginBottom: "25px", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Radio.Group value={selectedView} onChange={(e) => setSelectedView(e.target.value)}>
                      <Radio.Button value={TimeOffView.Calendar} type="primary">Calendar</Radio.Button>
                      <Radio.Button value={TimeOffView.Table}>Table</Radio.Button>
                    </Radio.Group>
                    {
                        selectedView === TimeOffView.Table &&
                        <RangePicker
                            format="D MMM, YY"
                            style={{width: "300px", borderRadius: 5, marginLeft: 10}}
                            value={selectedDateRange as any}
                            onChange={(v) => setSelectedDateRange(v as any)}
                        />
                    }
                    </div>
                  <div>
                    <Select
                        placeholder="Select status"
                        onChange={(value: number) => setSelectedStatus(value)}
                        style={{ width: "200px", marginRight: 10 }}
                        showSearch={false}
                        defaultValue={selectedStatus}
                        allowClear={true}
                    >
                      {getTimeOffStatusesList()?.map((status: KeyValue) => (
                          <Select.Option key={status.key} value={status.value}>
                            {status.value}
                          </Select.Option>
                      ))}
                    </Select>

                    {
                        isMyData &&
                        <Button
                            style={{ flex: 1, marginLeft:10, borderRadius: 4 }}
                            type="primary"
                            onClick={() => { toggleRequestTimeOffModal(true); }} >
                          Request Time Off
                        </Button>
                    }
                  </div>
                </div>
            }

            {
                selectedView === TimeOffView.Table &&
                <>
                  {timeOffRequests ? (
                      <TimeOffTable
                          showEmployeeName={false}
                          totalCount={pagination.totalCount || 0}
                          currentPage={pagination.pageNumber}
                          changePage={(page) => getUserTimeOffProfileData(page, queryParams['userId'] || '', selectedView)}
                          requests={timeOffRequests}
                          refreshData={() => getUserTimeOffProfileData(pagination.pageNumber, queryParams['userId'] || '', selectedView)}
                      />
                  ) : (
                      <div style={{ color: "gray", fontStyle: "italic" }}>
                        There are no requests.
                      </div>
                  )}
                </>
            }
          </>
      )}

      {
          selectedView === TimeOffView.Calendar &&
          <div style={{paddingBottom: 50}}>
            <TimeOffCalendar
                isSelectable={isMyData}
                publicHolidays={publicHolidaysSeed}
                currentDate={currentCalendarMonth}
                data={FullCalendarHelper.preprocessData(timeOffRequests, publicHolidaysSeed)}
                refreshData={(start: any, end: any) => {
                  getUserTimeOffProfileData(pagination.pageNumber, queryParams['userId'] || '',  selectedView, [start,end])
                  getUserProfileMetadata(queryParams['userId'] || '');
                }}
            />
          </div>
      }

      {
          (isMyData || userTimeOffProfileMetadata?.user && !userTimeOffProfileMetadata?.hasRestrictedTimeOffData) &&
          <div style={{paddingBottom: 50}}>
            <TimeOffHistoryLogs historyLogs={historyLogs} />
          </div>
      }

      <RequestTimeOffModal
          isVisible={displayRequestTimeOffModal}
          publicHolidays={publicHolidaysSeed}
          onClose={() => toggleRequestTimeOffModal(false)}
          onSuccess={() => {
            toggleRequestTimeOffModal(false);
            initializeData();
            getUserProfileMetadata(queryParams['userId'] || '');
          }}
      />
    </>
  );
};
