import { Col, Layout, Menu, Row } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../../App";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { LogoutIcon } from "../../../assets/icons/SidebarMenu/Logout";
import { SidebarToggleIcon } from "../../../assets/icons/SidebarMenu/SidebarToggleIcon";
import Logo from "../../../assets/images/logo.png";
import { useAuthFlow } from "../../auth/useAuthFlow";
import { SidebarProfileAvatar } from "./SidebarAvatar";
import menuItems, { IMenuItem, IRoute } from "./menuItems";

export const SidebarMenu = () => {
  const { isAdmin, isNormalUser, isOperationalUser, isForecastUser } =
    useUserRoles();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const { Sider } = Layout;
  const { handleLogout } = useAuthFlow();
  const [selectedKey, setSelectedKey] = useState([] as string[]);

  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem("internal-app-sidebar-collapsed") === "true"
      ? true
      : false
  );

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    localStorage.setItem("internal-app-sidebar-collapsed", `${!isCollapsed}`);
  };

  const roleFunctionMap: Record<string, () => boolean> = {
    admin: isAdmin,
    normalUser: isNormalUser,
    operational: isOperationalUser,
    forecast: isForecastUser,
    teamLead: () => user.teamLeaderProjectIds.length > 0,
  };

  const isVisible = (val: IMenuItem | IRoute) => {
    if (!val.visibleFor?.length) {
      return true;
    }
    return val.visibleFor.some((role) => {
      const checkFunction = roleFunctionMap[role];
      return checkFunction ? checkFunction() : false;
    });
  };

  const getSelectedMenuItemKey = () => {
    const selectedKey: string[] = [];

    const filteredItems = menuItems
      .filter((item) => isVisible(item))
      .map((item) => ({
        ...item,
        routes: item.routes.filter((route) => isVisible(route)),
      }));
    for (const item of filteredItems) {
      if (selectedKey.length) {
        break;
      }
      for (const route of item.routes) {
        if (location.pathname === route.route) {
          if (item.routes.length > 1) {
            selectedKey.push(route.key);
            break;
          } else {
            selectedKey.push(item.key);
            break;
          }
        }
      }
    }

    return selectedKey;
  };

  useEffect(() => {
    setSelectedKey(getSelectedMenuItemKey());
  }, [location, isCollapsed]);

  return (
    <Sider
      collapsible
      collapsed={isCollapsed}
      trigger={null}
      style={{
        height: "100vh",
        overflowY: "auto",
        background:
          "linear-gradient(155deg, #137AE9 0%, #06062D 100%) 0% 0% no-repeat padding-box",
      }}
      width="250"
    >
      <Row
        style={{ marginTop: 30, marginBottom: 30 }}
        align="middle"
        justify={isCollapsed ? "center" : "space-between"}
      >
        <Col>
          <img
            style={{
              maxWidth: 48,
              display: isCollapsed ? "none" : "block",
              marginLeft: 30,
            }}
            alt="company logo"
            src={Logo}
          />
        </Col>
        <Col style={{ marginRight: isCollapsed ? 0 : 10 }}>
          <SidebarToggleIcon
            rotate={isCollapsed ? 0 : 180}
            onClick={toggleSidebar}
          />
        </Col>
      </Row>

      <SidebarProfileAvatar isCollapsed={isCollapsed} user={user} />

      <Menu
        style={{
          width: "100%",
          fontSize: "130%",
          background: "transparent",
        }}
        theme="dark"
        mode="inline"
        selectedKeys={selectedKey}
      >
        {menuItems
          .filter((item) => isVisible(item))
          .map((item) => {
            const visibleRoutes = item.routes.filter((route) =>
              isVisible(route)
            );

            if (visibleRoutes.length > 1) {
              return (
                <SubMenu title={item.label} key={item.key} icon={item.icon()}>
                  {visibleRoutes.map((route) => (
                    <Menu.Item key={route.key}>
                      <Link to={route.route}>{route.label}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            } else if (visibleRoutes.length === 1) {
              return (
                <Menu.Item key={item.key} icon={item.icon()}>
                  <Link to={visibleRoutes[0].route}>{item.label}</Link>
                </Menu.Item>
              );
            }
            return null;
          })}
        <Menu.Item icon={<LogoutIcon />} onClick={handleLogout} key="logout">
          Log out
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
