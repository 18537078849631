import { GlobalOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { OfficePageContext } from ".";
import { Card, Skeleton, Space } from "antd";

export const LocationCard = () => {
  const officeLocation = useContext(OfficePageContext);
  const { officeData, loading } = officeLocation;

  return (
    <Card
      style={{ marginBottom: 16 }}
      title="Office Details"
      headStyle={{ backgroundColor: "#fafafa" }}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Space direction="vertical" size="middle">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GlobalOutlined style={{ marginRight: 8, color: "#a8a8a8" }} />
              <div style={{ color: "#a8a8a8", marginRight: "10px" }}>
                Address{" "}
              </div>
              <div style={{ fontWeight: "bold" }}> {officeData?.address}</div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <GlobalOutlined style={{ marginRight: 8, color: "#a8a8a8" }} />
              <div style={{ color: "#a8a8a8", marginRight: "10px" }}>City </div>
              <div style={{ fontWeight: "bold" }}> {officeData?.city}</div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <GlobalOutlined style={{ marginRight: 8, color: "#a8a8a8" }} />
              <div style={{ color: "#a8a8a8", marginRight: "10px" }}>
                Country{" "}
              </div>
              <div style={{ fontWeight: "bold" }}> {officeData?.country}</div>
            </div>
          </Space>
        </div>
      )}
    </Card>
  );
};
