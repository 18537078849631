import {TimeOffRecord} from "../interfaces/time-off-record.interface";
import {getTimeOffStatusColorForCalendar, getTimeOffTypeText} from "./time-off-general.helper";
import {TimeOffRequestType} from "../enums/time-off-request-type.enum";
import {PublicHoliday} from "../interfaces/public-holiday.interface";
import moment from "moment";

export class FullCalendarHelper {
    static preprocessData(data: Array<TimeOffRecord>, publicHolidays: PublicHoliday[]) {
        const timeOffs = data.map(timeOff => {
            return {
                id: timeOff.id,
                start: moment(timeOff.startDate).toDate(),
                end: moment(timeOff.endDate).add(1,'day').toDate(),
                title: FullCalendarHelper.computeEventName(timeOff),
                allDay: true,
                backgroundColor: getTimeOffStatusColorForCalendar(timeOff.approvalStatus),
                borderColor: getTimeOffStatusColorForCalendar(timeOff.approvalStatus),
                textColor: 'black',
                extendedProps: timeOff
            }
        })

        const holidays = publicHolidays.map(holiday => {
            return {
                id: holiday.id,
                start:holiday.date,
                end: holiday.date,
                title: holiday.name,
                allDay: true,
                backgroundColor: 'lightgray',
                borderColor: 'lightgray',
                textColor: 'black',
                extendedProps: {...holiday, isPublicHoliday: true},
                editable: false,
            }
        })

        return [...timeOffs, ...holidays];
    }

    private static computeEventName(timeOff: TimeOffRecord): string {
        if(timeOff.type === TimeOffRequestType.Holiday) {
            return timeOff.user.lastName + ' ' + timeOff.user.firstName;
        }

        return timeOff.user.lastName + ' ' + timeOff.user.firstName + ' (' + getTimeOffTypeText(timeOff.type) + ')';
    }
}
