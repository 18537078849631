import React, {useRef, useState} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid'
import {TimeOffRecord} from "../_core/interfaces/time-off-record.interface";
import {RequestTimeOffModal} from "./RequestTimeOffModal";
import moment from "moment";
import {PublicHoliday} from "../_core/interfaces/public-holiday.interface"; // a plugin!

export const TimeOffCalendar: React.FC<{data: any, publicHolidays: PublicHoliday[], currentDate: any,  refreshData: any, isSelectable: boolean}> = ({data, publicHolidays, currentDate, refreshData, isSelectable}) =>  {
    const didMount = useRef(false);

    const [selectedRecord, setSelectedRecord] = useState<TimeOffRecord | null>(null);
    const [selectedDateRange, setSelectedDateRange] = useState<any>(null);

    const [currentViewDates, setCurrentViewDates] = useState<any>([]);

    const handleEventSelect = (selectInfo: any) => {
        if(selectInfo.event.extendedProps.isPublicHoliday) {
            return;
        }
        setSelectedRecord(selectInfo.event.extendedProps);
    }

    const handleDateSelect = (selectInfo: any) => {
        setSelectedDateRange([moment(selectInfo.startStr), moment(selectInfo.endStr).add(-1, 'days')])
    }

    const handleMonthChange = (selectInfo: any) => {
        if(didMount.current) {
           refreshData(selectInfo.startStr, selectInfo.endStr);
           setCurrentViewDates([selectInfo.startStr, selectInfo.endStr])
        } else {
            didMount.current = true;
            setCurrentViewDates([selectInfo.startStr, selectInfo.endStr])
        }
    }

    return (
        <>
            <FullCalendar
                plugins={[ dayGridPlugin, interactionPlugin ]}
                initialView="dayGridMonth"
                showNonCurrentDates={true}
                fixedWeekCount={false}
                height={800}
                events={data}
                selectable={isSelectable}
                firstDay={1}
                eventClick={handleEventSelect}
                select={handleDateSelect}
                datesSet={(event: any) => handleMonthChange(event)}
                initialDate={moment(currentDate).toDate()}
                buttonText={{
                    today: 'Today',
                }}
            />

            {
                selectedRecord &&
                <RequestTimeOffModal
                    isVisible={true}
                    publicHolidays={publicHolidays}
                    onClose={() => setSelectedRecord(null)}
                    onSuccess={() => {setSelectedRecord(null); refreshData(currentViewDates[0], currentViewDates[1])}}
                    timeOffRequest={selectedRecord}
                />
            }

            {
                selectedDateRange &&
                <RequestTimeOffModal
                    isVisible={true}
                    publicHolidays={publicHolidays}
                    onClose={() => setSelectedDateRange(null)}
                    onSuccess={() => {setSelectedDateRange(null); refreshData(currentViewDates[0], currentViewDates[1])}}
                    defaultDateRange={selectedDateRange}
                />
            }
        </>

    )
}


