import { useEffect, useState, useCallback, useContext } from "react";
import { message } from "antd";
import { EditOfficeModal } from "./EditOfficeModal";
import { ApiClientContext } from "../../App";

export const useOffices = () => {
  const [addOfficeModal, setAddOfficeModal] = useState<boolean>(false);
  const [offices, setOffices] = useState([]);
  const [editOfficeModal, setEditOfficeModal] = useState<boolean>(false);
  const [officeName, setOfficeName] = useState(null);
  const [officeAddress, setOfficeAddress] = useState(null);
  const [officeId, setOfficeId] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [officeUsersTableData, setOfficeUsersTableData] = useState(null);
  const [deleteOfficeModal, setDeleteOfficeModal] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [managers, setManagers] = useState({});
  const [currentOffice, setCurrentOffice] = useState({});

  const { apiClient } = useContext(ApiClientContext);

  const getOffices = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/api/offices");

      if (response.status === 200) {
        setOffices(
          response.data.map((row: any) => ({
            ...row,
            key: row.id,
          }))
        );
      }
    } catch (error) {}
    setLoading(false);
  }, []);

  const getManagers = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/api/users/office-manager");

      if (response.status === 200) {
        setManagers(response.data);
      }
    } catch (error) {
      message.error("Could not get managers!");
    }
    setLoading(false);
  };

  useEffect(() => {
    getOffices();
    getManagers();
  }, []);

  const handleAddOffice = async (office: any) => {
    setLoading(true);
    try {
      const response = await apiClient.post("/api/offices", {
        city: office.city,
        address: office.address,
        country: office.country,
        managerId: office.manager,
        timezone: office.timezone,
        startWorkingHour: office.startWorkingHour,
        latitude: office.latitude,
        longitude: office.longitude,
      });

      if (response.status === 200) {
        if (response.data.data === false) {
          message.error("This office already exists!");
        } else {
          getOffices();
          setAddOfficeModal(false);
          message.info("New Office Added!");
        }
      }
    } catch (error) {
      message.error("Could not add Office");
    }
    setLoading(false);
  };

  const handleEditOffice = async (office: any) => {
    setLoading(true);
    try {
      const response = await apiClient.put(`/api/offices/${officeId}`, {
        city: office.city,
        address: office.address,
        country: office.country,
        managerId: office.manager,
        timezone: office.timezone,
        startWorkingHour: office.startWorkingHour,
        latitude: office.latitude,
        longitude: office.longitude,
      });
      if (response.status === 200) {
        getOffices();
        message.info("Office updated!");
      }
    } catch (error) {
      message.error("Could not update office");
    }
    setEditOfficeModal(false);
    setLoading(false);
  };

  const getOfficeById = async (officeId: string) => {
    setLoading(true);

    try {
      const response = await apiClient.get(
        `/api/offices/get-by-id/${officeId}`
      );

      if (response.status === 200) {
        setOfficeName(response.data.data.name);
        setOfficeAddress(response.data.data.address);
        setOfficeId(response.data.data.id);
      }
    } catch (error) {
      message.error("Could not get offices");
    }

    try {
      const response = await apiClient.post(
        `/api/offices/get-users-by-office/${officeId}`,
        {
          id: officeId,
        }
      );
      if (response) {
        if (response.status === 200) {
          setOfficeUsersTableData(
            response.data.data.map((row: any) => ({
              key: row.id,
              ...row,
            }))
          );
        }
      }
    } catch (error) {
      message.error("Could Not Retrieve users");
    }

    setLoading(false);
  };

  // const handleDeleteOffice = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await apiClient.delete(
  //       `/api/offices/delete-office/${officeId}`
  //     );
  //     if (response?.status === 200) {
  //       getOffices();

  //       message.info("Office Deleted!");
  //     }
  //   } catch (error) {
  //     if (error.response)
  //       if (error.response.status === 500)
  //         message.error("Office was not deleted (server error)!");
  //       else message.error("Office was not deleted!");
  //   }
  //   setLoading(false);
  //   setDeleteOfficeModal(false);
  // };

  return {
    offices,
    currentOffice,
    setCurrentOffice,
    managers,
    getManagers,
    addOfficeModal,
    setAddOfficeModal,
    handleAddOffice,
    loading,
    getOfficeById,
    officeName,
    officeAddress,
    officeId,
    handleEditOffice,
    // deleteOfficeModal,
    setDeleteOfficeModal,
    editOfficeModal,
    setEditOfficeModal,
    // handleDeleteOffice,
    officeUsersTableData,
    setOfficeId,
    searchValue,
    setSearchValue,
  };
};
