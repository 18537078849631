import { Input } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import { ForecastTableContext } from ".";
import { Technology } from "../types";

interface EmployeeTechnologyCellInputFieldProps {
  technology: Technology;
  employeeId: string;
  projectId: string;
}
export const EmployeeTechnologyCellInputField = (props: EmployeeTechnologyCellInputFieldProps) => {
  const { technology, employeeId, projectId } = props;
  const { updateForecast, workedTime } = useContext(ForecastTableContext);
  const [forecastValue, setForecastValue] = useState(
    workedTime
      ? technology.currentMonthWorked.toString()
      : technology.currentMonthForecast.toString()
  );

  useEffect(() => {
    setForecastValue(
      workedTime
        ? technology.currentMonthWorked.toString()
        : technology.currentMonthForecast.toString()
    );
  }, [technology]);
  const debouncedCall = useDebouncedCallback((newForecast: string) => {
    const forecastTime = parseInt(newForecast);
    updateForecast({
      forecastTime,
      projectId,
      technologyId: technology.id,
      userId: employeeId,
    });
  }, 1000);

  useEffect(() => {
    if (forecastValue !== technology.currentMonthForecast.toString()) {
      debouncedCall(forecastValue);
      if (forecastValue === "") {
        debouncedCall.cancel();
      }
    }
  }, [forecastValue]);
  return (
    <Input
      style={{
        borderRadius: 5,
        float: "right",
        fontSize: 12,
        width: 45,
      }}
      onChange={(e) => {
        const { value } = e.target;
        const reg = /^[1-9]\d{0,2}(?:\,\d{1,3})?$/;
        if ((!isNaN(Number.parseInt(value)) && reg.test(value)) || value === "") {
          setForecastValue(value);
        }
      }}
      onBlur={() => {
        if (forecastValue === "") {
          setForecastValue(technology.currentMonthForecast.toString());
        }
      }}
      size="small"
      value={forecastValue}
    />
  );
};
