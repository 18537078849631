import { AliwangwangOutlined } from "@ant-design/icons";
import { DashboardIcon } from "../../../assets/icons/SidebarMenu/DashboardIcon";
import { ForecastIcon } from "../../../assets/icons/SidebarMenu/ForecastIcon";
import { GeneralSettingsIcon } from "../../../assets/icons/SidebarMenu/GeneralSettingsIcon";
import { InventoryIcon } from "../../../assets/icons/SidebarMenu/InventoryIcon";
import { OfficesIcon } from "../../../assets/icons/SidebarMenu/OfficesIcon";
import { ProjectsIcon } from "../../../assets/icons/SidebarMenu/ProjectsIcon";
import { ReportBugIcon } from "../../../assets/icons/SidebarMenu/ReportBugIcon";
import { TimeOffIcon } from "../../../assets/icons/SidebarMenu/TimeOffIcon";
import { TimeTrackerIcon } from "../../../assets/icons/SidebarMenu/TimeTrackerIcon";

export type visibilityOption =
  | "admin"
  | "normalUser"
  | "operational"
  | "teamLead"
  | "forecast";

export interface IMenuItem {
  key: string;
  label: string;
  icon: any;
  isSelected?: boolean;
  visibleFor?: visibilityOption[];
  routes: IRoute[];
}

export interface IRoute {
  key: string;
  label: string;
  route: string;
  visibleFor?: visibilityOption[];
}

const menuItems: IMenuItem[] = [
  {
    key: "dashboardItem",
    label: "Dashboard",
    icon: DashboardIcon,
    routes: [
      {
        key: "dashboard",
        label: "Dashboard",
        route: "/dashboard",
      },
    ],
  },
  {
    key: "offices",
    label: "Offices",
    icon: OfficesIcon,
    routes: [
      {
        key: "employees",
        label: "Employees",
        route: "/employees",
      },
      {
        key: "allOffices",
        label: "Offices",
        route: "/allOffices",
      },
    ],
  },
  {
    key: "projects",
    label: "Projects",
    icon: ProjectsIcon,
    routes: [
      {
        key: "allProjects",
        label: "Projects",
        route: "/projects",
      },
      {
        key: "clients",
        label: "Clients",
        route: "/clients",
        visibleFor: ["admin"],
      },
    ],
  },
  {
    key: "timeTracking",
    label: "Time tracking",
    icon: TimeTrackerIcon,
    routes: [
      {
        key: "trackYourTime",
        label: "Track your time",
        route: "/trackYourTime",
        visibleFor: ["normalUser", "teamLead"],
      },
      {
        key: "reports",
        label: "Reports",
        route: "/reports/company-overview",
        visibleFor: ["admin", "forecast"],
      },
    ],
  },
  {
    key: "timeOff",
    label: "Time off",
    icon: TimeOffIcon,
    routes: [
      {
        key: "teamTimeOff",
        label: "Team time off",
        route: "/teamTimeOff",
        visibleFor: ["admin", "operational", "normalUser"],
      },
      {
        key: "timeOffProfile",
        label: "My time off",
        route: "/timeOffProfile",
        visibleFor: ["admin", "operational", "normalUser"],
      },
      {
        key: "timeOffAdministration",
        label: "Time Off Administration",
        route: "/time-off-administration",
        visibleFor: ["admin"],
      },
    ],
  },
  {
    key: "inventory",
    label: "Inventory",
    icon: InventoryIcon,
    routes: [
      {
        key: "myItems",
        label: "My Items",
        route: "/inventory/my-items",
      },
      {
        key: "itemsRequests",
        label: "Items requests",
        route: "/inventory/requests",
        visibleFor: ["admin", "operational"],
      },
      {
        key: "brands",
        label: "Brands",
        route: "/inventory/brands",
        visibleFor: ["admin", "operational"],
      },
      {
        key: "models",
        label: "Models",
        route: "/inventory/models",
        visibleFor: ["admin", "operational"],
      },
      {
        key: "items",
        label: "Items",
        route: "/inventory/items",
        visibleFor: ["admin", "operational"],
      },
    ],
  },
  // {
  //   key: "recruitment",
  //   label: "Recruitment",
  //   icon: RecruitmentIcon,
  //   route: "",
  //   visibleFor: ["admin"],
  // },
  {
    key: "forecast",
    label: "Forecast",
    icon: ForecastIcon,
    visibleFor: ["admin", "forecast"],
    routes: [
      {
        key: "forecastHours",
        label: "Months",
        route: "/forecast",
      },
      {
        key: "worked",
        label: "Worked",
        route: "/forecast/worked",
      },
    ],
  },
  {
    key: "generalSettings",
    label: "Technologies",
    icon: GeneralSettingsIcon,
    visibleFor: ["admin"],
    routes: [
      {
        key: "technologies",
        label: "Technologies",
        route: "/technology",
      },
    ],
  },
  {
    key: "reportBugItem",
    label: "Report bug",
    icon: ReportBugIcon,
    routes: [
      {
        key: "reportBug",
        label: "Report bug",
        route: "/report-bug",
      },
    ],
  },
];

export default menuItems;
